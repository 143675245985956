<template>
  <div class="page-title">
    <div class="login-container">
      <div class="card-header">
        <h2 class="card-title text-dark">Login</h2>
      </div>
      <div class="card-body">
        <form @submit.prevent="login">
          <input-field
            class="login-input-field"
            v-model="username"
            ref="username"
            :data="{ label: 'Username' }"
            dataName="username"></input-field>
          <input-field
            class="login-input-field"
            v-model="password"
            ref="password"
            :data="{
              label: 'Password',
              type: 'password',
            }"
            dataName="password"></input-field>
          <div class="button-container">
            <button
              class="button button-dark"
              :class="buttonDisabled ? 'disabled' : ''">
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InputField from "@/components/InputField.vue";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      buttonDisabled: false,
    };
  },
  components: {
    InputField,
  },
  mounted() {
    document.title = "login | icandoathing";
  },
  methods: {
    login() {
      this.$store.commit("setLoading", true);
      this.buttonDisabled = true;
      axios
        .post("/api/v1/token/login/", {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          this.$store.commit("setToken", res.data.auth_token);
          this.$store.commit("setAuthentication", true);
          this.$notify({
            title: "Log In!",
            body: "You have been successfully logged in!",
            icon: "",
            type: "success",
          });
          axios.get("/api/v1/users/me").then((res) => {
            this.$store.commit("setLoading", false);
            this.$store.commit("setUserData", res.data);
            const toPath = this.$route.query.to || "/";
            this.$router.push(toPath);
          });
        })
        .catch((err) => {
          this.buttonDisabled = false;
          if (!err.response || !err.response.data) {
            let customError = "There's an issue, pleasy try again later.";
            if (this.$refs.username.shownErrors.includes(customError)) {
              this.$refs.username.shake();
            } else {
              this.$refs.username.shownErrors.push(customError);
            }
          }
          Object.keys(err.response.data).forEach((key) => {
            err.response.data[key].forEach((error) => {
              if (!this.$refs[key]) {
                key = "username";
              }

              if (this.$refs[key].shownErrors.includes(error)) {
                this.$refs[key].shake();
              } else {
                this.$refs[key].shownErrors.push(error);
              }
            });
          });
          this.$store.commit("setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.login-container {
  display: flex;
  flex-direction: column;
  background-color: $grey-300;
  border-radius: $border-radius;
  padding: 1rem;
  min-width: $navbar-width-lg;
}
.card-title {
  font-size: 2rem;
  margin: 0rem;
  margin-bottom: 1rem;
  color: $primary-600;
  font-weight: 400;
  text-transform: lowercase;
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 1.5rem;
}
.login-input-field {
  width: 35ch;
}
</style>
